<template>
  <div>
    <!-- <van-collapse v-model="activeNames">
      <van-collapse-item :title=titleMsg >
        <van-field v-model="amount" label="文本" placeholder="请输入金额" />
        <van-field v-model="amount" type="number" label="校服费"  right-icon="edit" />
      </van-collapse-item>
    </van-collapse> -->

    <!-- <van-panel title="校服费用" desc="" @click=changeEditVis :status=titleMsg icon="edit">
      <van-field v-if=showField v-model="amount" type="number" label="校服费"  right-icon="edit" />
    </van-panel> -->

    <van-cell title="校服费用" :value="titleMsg" />
    <!-- <van-field ref="payField" v-if=showField v-model="amount" class="payField" type="number" label="校服费" @blur="editField=true"  :readonly=editField >
      <template #button>
        <van-icon name="edit"  @click="focusEditVis" />
      </template>
    </van-field> -->

    <van-cell-group title="请填写下缴费信息">
      <van-cell required title="所属学校" :value="schoolName"> {{ schoolName }}</van-cell>
      <van-cell required title="所属年级" :value="gradeName" is-link @click="showGrade = true"></van-cell>
      <van-popup v-model="showGrade" round position="bottom">
        <van-picker title="所属年级" :columns="grades" @confirm="gradeConfirm" @cancel="showGrade = false" show-toolbar />
      </van-popup>
      <van-cell required title="所属班级" :value="className" is-link @click="showClass = true"></van-cell>
      <van-popup v-model="showClass" position="bottom">
        <van-picker title="所属班级" :columns="classes" @confirm="classConfirm" @cancel="showClass = false" show-toolbar />
      </van-popup>
      <div v-if="clothes.length > 0">
       <!-- <van-field v-for="(item) in clothes" :key="item.text"   v-model="clothNum[item.code]"  :label= " item.text + '('+item.price  +'/件)'"
          placeholder="请输入件数" @blur="calcAmount" />-->
        <van-field v-for="(item) in clothes" :key="item.text"   v-model="clothNum[item.code]"  :label= "item.text"
                   placeholder="请输入件数" @blur="calcAmount" />
      </div>
      <!-- <van-popup v-model="showClothesType" position="bottom">
        <van-picker title="服装类别" :columns="clothes" @confirm="typeConfirm" @cancel="showClothesType = false"
          show-toolbar />
      </van-popup> -->

      <!-- <van-field required v-model="type" label="服装类别" placeholder="请选择" /> -->
      <van-field required v-model="clothesSize" label="校服尺码" placeholder="输入购买校服尺码:如160，165" />
      <van-field required v-model="studentName" label="学生姓名" placeholder="请输入用户名" />
      <van-field required v-model="conactNo" label="联系方式" type="tel" placeholder="请输入手机号" />
      <van-field v-model="studentNo" label="班级学号" type="number" maxlength="6" placeholder="请输入班级学号" />
      <van-field v-model="address" label="收货地址" type="text" placeholder="默认发货学校，其他地址自付快递费" />


    </van-cell-group>

    <!-- <van-coupon-cell
      :coupons="coupons"
      :chosen-coupon="chosenCoupon"
      title="校服费用"
      @click=changeEditVis
    />
    <van-field v-if=showField v-model="amount" type="number" label="校服费"  right-icon="edit" /> -->


    <!-- <van-form >
      <van-picker
        title="学校名称"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
      />
      <van-field v-model="schoolName" label="文本" placeholder="请输入用户名" />
      <van-field v-model="gradeName" label="文本" placeholder="请输入用户名" />
      <van-field v-model="className" label="文本" placeholder="请输入用户名" />
      <van-field v-model="studentName" label="文本" placeholder="请输入用户名" />
      <van-field v-model="conactNo" label="文本" placeholder="请输入用户名" />
      <van-field v-model="type" label="文本" placeholder="请输入用户名" />
    </van-form> -->

    <van-cell-group title="请选中支付方式">

      <van-cell title="微信支付" icon="wechat" clickable @click="radio = '1'">
        <template #right-icon>
          <van-radio checked />
        </template>
      </van-cell>
    </van-cell-group>

    <div class="bottomLayout">
      <van-button class="submitBtn" type="primary" size="large" @click="createOrder">缴费</van-button>
    </div>

  </div>
</template>

<script>
import { Toast } from 'vant';
import request from '../utils/request'

export default {
  name: 'Pay',
  data() {
    return {
      accessCode: '',
      activeNames: ['1'],

      amount: 0,
      schoolId: 0,
      schoolName: '',
      gradeName: '',
      className: '',
      classId: '',
      clothesId: '',
      studentName: '',
      conactNo: '',
      studentNo: '',
      type: '',
      typeName: '',
      checked: '1',
      clothNum: [0 , 0 ,0],
      address: '',
      clothesSize:'',

      showPay: false,

      showField: false,
      editField: true,
      focusState: false,

      showGrade: false,
      showClass: false,
      showClothesType: false,

      gradeData: {},
      grades: [],
      classes: [],
      clothes: [],
      clothPrice: {},
      clothTypeId: {},
    }
  },
  updated() {
    if (this.editField == false) {
      if (this.amount == 0) {
        this.amount = '';
      }
      this.$refs.payField.focus();
    }

  },
  computed: {
    titleMsg: function () {
      // `this` points to the vm instance
      return "￥" + this.amount;
    },
    classAllName: function () {
      if (this.schoolName != '') {
        return this.schoolName + "|" + this.gradeName + "|" + this.className;
      }
      return '';
    }
  },
  created() {

  },
  mounted() {
    if (!this.$route.query.code) {
      window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc27cd5a3d5c45c0c&redirect_uri=http%3A%2F%2Fwww.kuhuashi.cn%2F%23%2Findex&response_type=code&scope=snsapi_base&state=" + this.$route.query.schoolId + "#wechat_redirect";
    } else {
      this.accessCode = this.$route.query.code

      if (this.$route.query.state) {
        this.schoolId = this.$route.query.state;
      }
      this.initData();
    }
  },
  methods: {
    initData() {
      // 微信认证 获取
      request({
        method: 'GET',
        url: '/auth/callWechatCode/' + this.accessCode,
      });

      request({
        method: 'GET',
        url: '/class-group/getSchoolInfo/' + this.schoolId,
      }).then((response) => {
        let schInfo = response.data.data;
        this.schoolName = schInfo.name;
      })

      request({
        method: 'GET',
        url: '/class-group/getClassTree/' + this.schoolId,
      }).then((response) => {
        this.gradeData = response.data.data;
        let keys = Object.keys(this.gradeData);
        this.grades = keys;
      })
    },
    createOrder() {
      if (this.gradeName == '') {
        Toast("提交失败：请选择年级信息！")
        return
      }
      if (this.className == '') {
        Toast("提交失败：请选择班级信息！");
        return
      }

      if (this.studentName == '') {
        Toast("提交失败：请选择学生姓名！");
        return
      }
      if (this.conactNo == '') {
        Toast("提交失败：请选择联系方式！");
        return
      }
      if(typeof(this.clothNum) == "undefined"|| this.clothNum == null || this.clothNum.length == 0){
        Toast("提交失败：请选择服装数量！");
        return
      }
      if(this.clothNum[0]<1 && this.clothNum[1]<1 && this.clothNum[2]<1){
        Toast("提交失败：请选择服装数量！");
        return
      }
      console.log("clothNum" , this.clothNum)

      request({
        method: 'POST',
        url: '/order/createOrder',
        data: {
          "studentName": this.studentName,
          "studentNo": this.studentNo,
          "contactNo": this.conactNo,
          "classId": this.classId,
          "code": this.accessCode,
          "summerNum": this.clothNum[0],
          "autumnNum": this.clothNum[1],
          "winterNum": this.clothNum[2],
          "address": this.address,
          "clothesSize":this.clothesSize,
        }
      }).then((response) => {
        if (response.data.code == "0") {
          if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
              document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
          } else {
            this.onBridgeReady(response.data.data);
          }

        } else {
          Toast("下单失败！");
        }
      })
    },
    onBridgeReady(param) {

      WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appID": param.AppID,     //公众号ID，由商户传入
        "appid": param.AppID,
        "appId": param.AppID,
        "timeStamp": param.timeStamp,     //时间戳，自1970年以来的秒数
        "nonceStr": param.nonceStr,      //随机串
        "package": param.package,
        "signType": "RSA",     //微信签名方式：
        "paySign": param.paySign     //微信签名
      },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            Toast("缴费成功")
          } else {
            Toast("缴费失败：" + res.err_msg)
          }
        });
    },
    gradeConfirm(value) {
      this.classes = [];
      this.clothes = []
      if (this.gradeName == '' || this.gradeName != value) {
        let classDatas = this.gradeData[value]
        for (var i = 0; i < classDatas.length; i++) {
          var classD = {}
          classD['text'] = classDatas[i].name;
          classD['value'] = classDatas[i].id;
          this.classes.push(classD);
        }
        this.classId = '';
        this.className = '';
        this.type = '';
        this.typeName = '';
      }

      this.gradeName = value;
      this.showGrade = false;

      this.amount = '';
      this.clothesId = '';
    },

    classConfirm(node) {
      this.clothes = []
      let value = node.value;

      if (this.classId == '' || this.classId != value.toString()) {
        for (var clsKey in this.gradeData[this.gradeName]) {
          let cls = this.gradeData[this.gradeName][clsKey]
          if (cls.id == value) {
            let clothesInfos = cls.clothesInfoList;
            for (var i = 0; i < clothesInfos.length; i++) {
              let cloth = {};
              cloth['value'] = clothesInfos[i]['clothType'];
              if (clothesInfos[i]['clothType'] == 0) {
                cloth['text'] = '夏服' + '('+ clothesInfos[i]['price'] + '/套)';
                cloth['code'] = 0;
                cloth['price'] = clothesInfos[i]['price'];
              } else if (clothesInfos[i]['clothType'] == 1) {
                cloth['text'] = '秋服'+ '(' + clothesInfos[i]['price'] + '/套)';
                cloth['code'] = 1;
                cloth['price'] = clothesInfos[i]['price'];
              } else {
                let texe = '/件)'
                if(this.schoolId == 3){
                  texe = '/套)'
                }
                cloth['text'] = '冬服'+ '(' + clothesInfos[i]['price'] + texe;
                cloth['code'] = 2;
                cloth['price'] = clothesInfos[i]['price'];
              }
              this.clothPrice[clothesInfos[i]['clothType']] = clothesInfos[i]['price']
              this.clothTypeId[clothesInfos[i]['clothType']] = clothesInfos[i]['id']
              this.clothes.push(cloth)
            }
            break;
          }
        }
        this.classId = value;
        this.type = '';
        this.typeName = '';
        this.amount = '';
        this.clothesId = '';
      }

      this.className = node.text;
      this.showClass = false;
    },
    typeConfirm(node) {
      this.type = node.value;
      this.typeName = node.text
      this.amount = this.clothPrice[this.type]
      this.clothesId = this.clothTypeId[this.type]
      this.showClothesType = false;
    },

    changeEditVis() {
      this.showField = !this.showField;
      Toast("提示信息：" + this.showField)
    },
    focusEditVis() {
      this.editField = false;
    },
    calcAmount() {
      this.amount = 0
      if (this.clothNum[0]) {
        this.amount += this.clothPrice[0] * this.clothNum[0]
      }
      if (this.clothNum[1]) {
        this.amount += this.clothPrice[1] * this.clothNum[1]
      }
      if (this.clothNum[2]) {
        this.amount += this.clothPrice[2] * this.clothNum[2]
      }
      console.log("test" + this.amount)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bannerTitle {
  margin-left: 10px;
}

.payField {
  margin-left: 10px;
  width: 95%;
}

.bottomLayout {
  box-sizing: border-box;
  padding: 10px 10px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 22px;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0px;
  background: white;

  .submitBtn {
    height: 40px;
    background: #1883fd;
    border-radius: 6px;
    color: white;
    flex-grow: 2;
  }
}
</style>
