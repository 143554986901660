// 导入组件
import Vue from 'vue';
import Router from 'vue-router';

import Pay  from '@/components/Pay';


// 启用路由
Vue.use(Router);

// 导出路由 
const router =  new Router({
    mode: 'history',
    routes: [{
        path: '/',
        name: 'Pay',
        component: Pay 
    }]
})

export default router;